<template>
  <div :id="localId"  class="svg_wrapper"></div>
</template>

<script>
import mx_Core from './includes/mixins/zMixins'     

export default {
  name: "j-chart-disc-progress",
  mixins: [mx_Core],
  props: {
    id: String,
    modeExport: Boolean,
    getSelectItems: String,  

    FilterValues: { type: Object, default: () => ({}) },
    ColumnProps: { type: Array, default: () => ([]) },
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    Note: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    Queries: { type: Object, default: () => ({}) },
    JQueries: { type: Object, default: () => ({}) },
  },
  created() {

    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`

  },

  mounted() {

    this.register(this.draw)

  },

  methods: {

    draw() {

      if (!this.ready2draw) {
        return
      }
      this.clear()
      this.set_LocalValues()
      this.drawCanvas()
      this.drawNotes()
      this.drawTexts()
      this.drawing()
      this.complete()
      
    },

    drawing() {
      // Call functions
      this.draw_Forms()
      
      // for Exporting PDF file
      this.getItems = this.getSelectItems ? JSON.parse(this.getSelectItems) : {}
      if (this.getSelectItems) {
        this.draw_PDF()
      }
    },
  }
}
</script>