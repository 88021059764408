export default {

  methods: {
    
    draw_Timeline() {    

      let st = this.timeline.styles
      let tType = this.timeline.type

      let Timeline = this.canvasChart
      .append('g')
      .attr('transform', `translate(${0}, ${this.chart.height + .5})`)
      .style('font-family', 'roboto')
      

      // Week -----------------------------------------------------------------------
      if(tType == 'week') {
        Timeline
        .append('line')
        .attr('x1', this.chart.width).attr('y1', 0)
        .attr('x2', this.chart.width).attr('y2', st.week.lineY)
        .attr('stroke', '#bcbcbc').attr('stroke-width', .5)

        Timeline
        .selectAll('line1')
        .data(this.timeline.week)
        .enter()
        .append('line')
        .attr('x1', d => d.sx).attr('y1', 0)
        .attr('x2', d => d.sx).attr('y2', st.week.lineY)
        .attr('stroke', '#bcbcbc').attr('stroke-width', .5)

        if(st.week.visible == 'Y') {
          Timeline
          .selectAll('text1')
          .data(this.timeline.week)
          .enter()
          .append('text')
          .attr('x', d=> d.mx).attr('y', st.week.textY)
          .attr('font-size', st.week.tSize).attr('fill', st.week.tColor)
          .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
          .text(d => d.name)
        }
      }


      // Month -----------------------------------------------------------------------
      if(st.month.visible == 'Y') {
        Timeline
        .append('line')
        .attr('x1', this.chart.width).attr('y1', st.week.lineY)
        .attr('x2', this.chart.width).attr('y2', st.month.lineY)
        .attr('stroke', '#bcbcbc').attr('stroke-width', .5)

        Timeline
        .selectAll('line2')
        .data(this.timeline.month)
        .enter()
        .append('line')
        .attr('x1', d => d.sx).attr('y1', tType == 'month' ? 0 :  st.week.lineY) //revised
        .attr('x2', d => d.sx).attr('y2', st.month.lineY)
        .attr('stroke', '#bcbcbc').attr('stroke-width', .5)

        Timeline
        .selectAll('text2')
        .data(this.timeline.month)
        .enter()
        .append('text')
        .attr('x', d=> d.mx).attr('y', tType == 'month' ? st.month.textY -5 : st.month.textY ) //revised
        .attr('font-size', st.month.tSize).attr('fill', st.month.tColor)
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(d => d.name)
      }

      // Year -----------------------------------------------------------------------
      if(st.year.visible == 'Y') {
        Timeline
        .append('line')
        .attr('x1', this.chart.width).attr('y1', tType == 'month' ? 0 :  st.month.lineY) //revised
        .attr('x2', this.chart.width).attr('y2', st.year.lineY)
        .attr('stroke', '#bcbcbc').attr('stroke-width', .5)

        Timeline
        .selectAll('line3')
        .data(this.timeline.year)
        .enter()
        .append('line')
        .attr('x1', d => d.sx).attr('y1', st.month.lineY)
        .attr('x2', d => d.sx).attr('y2', st.year.lineY)
        .attr('stroke', '#bcbcbc').attr('stroke-width', .5)

        Timeline
        .selectAll('text3')
        .data(this.timeline.year)
        .enter()
        .append('text')
        .attr('x', d=> d.mx).attr('y', st.year.textY)
        .attr('font-size', st.year.tSize).attr('fill', st.year.tColor)
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(d => d.name)
      }
    },  



  }
}
